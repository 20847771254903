import {Autocomplete, TextField, Typography} from "@mui/material";
import React from "react";


export default function SelectBox({boxes, onSelectBox, selectedBoxId, setSelectedBoxId}) {


    const handleBoxChange = (event, newValue) => {
        if (newValue) {
            setSelectedBoxId(newValue.task_box_id || newValue.box_id);
            onSelectBox(newValue);
        } else {
            setSelectedBoxId(null);
            onSelectBox(null);
        }
    };

    return(
            <Autocomplete
                options={boxes}
                value={boxes.find((box) => (box.task_box_id || box.box_id) === selectedBoxId) || null}
                getOptionLabel={(box) => (box.task_box_name ? box.task_box_name : `Box ${boxes.indexOf(box) + 1}`)}
                onChange={handleBoxChange}
                renderInput={(params) => (
                    <TextField {...params} label="Select box" variant="outlined" fullWidth />
                )}
                renderOption={(props, box ) => (
                    <li {...props} key={box.task_box_id || box.box_id}>
                        {box.task_box_name ? box.task_box_name : <Typography>{`Box ${boxes.indexOf(box) + 1}`}</Typography>}
                    </li>
                )}
            />
    )
}