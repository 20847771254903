import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Tab, Tabs } from "@mui/material";
import DocTable from "./DocTable";
import DocInfo from "./DocInfo";
import {toast} from "react-toastify";
const baseRoute=process.env.REACT_APP_BASE_ROUTE
export default function DocsEditor({ taskDocs, onDocsFieldsChange, handleApproveDocs, onDocItemChange, onChangePriceCoef }) {
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    const handleDocButtonClick = (index) => {
        setSelectedTabIndex(index);
    };

    const handlePostDoc = async() => {
        try{

            const response = await fetch(`${baseRoute}/api/v1/docs/publish/po`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token'),
                },
                body: JSON.stringify({
                    source_sheet_name: taskDocs[selectedTabIndex].po_num,
                    products: taskDocs[selectedTabIndex].positions.map((item) => ({
                        ...item,
                        price: parseFloat(item.price),
                        netto: parseFloat(item.netto),
                        brutto: parseFloat(item.brutto),
                    })),
                })
            });

            if (!response.ok) {
                toast("Error post doc to Google Sheet", {
                    position: "top-center",
                    autoClose: 3000,
                    type: "error",
                });
                return
            } else {
                toast("Doc posted to Google Sheet", {
                    position: "top-center",
                    autoClose: 3000,
                    type: "success",
                });
            }

            return await response.json();
        } catch(error) {
            if (error.toString().includes("Failed to fetch")) {
                toast("Response timeout error", {
                    position: "top-center",
                    autoClose: 3000,
                    type: "error",
                });
                return
            }
            toast("An error occurred while posting documents", {
                position: "top-center",
                autoClose: 3000,
                type: "error",
            });
        }

    }
    const handlePostLabels = async() => {
        try{
            const response = await fetch(`${baseRoute}/api/v1/docs/publish/labels`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token'),
                },
                body: JSON.stringify({
                    products: taskDocs[selectedTabIndex].positions.filter((item) => item.item_type === "REAL"),
                    po_num: taskDocs[selectedTabIndex].po_num,
                })
            });

            if (!response.ok) {
                toast("Error post doc to Google Sheet", {
                    position: "top-center",
                    autoClose: 3000,
                    type: "error",
                });
                return
            } else {
                toast("Labels posted to Google Sheet", {
                    position: "top-center",
                    autoClose: 3000,
                    type: "success",
                });
            }

            return await response.json();
        } catch(error) {
            console.log(error.message)
            if (error.message.includes("Failed to fetch")) {
                toast("Response timeout error", {
                    position: "top-center",
                    autoClose: 3000,
                    type: "error",
                });
                return
            }
            toast("An error occurred while posting the labels", {
                position: "top-center",
                autoClose: 3000,
                type: "error",
            });
        }
    }


    return (
        <Grid container spacing={2} align={"center"}>
            <Grid item xs={12} sm={12} md={12}>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={6}>
                        <Grid container spacing={2}>
                            <Tabs
                                value={selectedTabIndex}
                                onChange={(event, newValue) => handleDocButtonClick(newValue)}
                                variant="scrollable"
                                scrollButtons="auto"
                            >
                                {taskDocs.map((taskDoc, index) => (
                                    <Tab
                                        key={index}
                                        label={taskDoc.po_num}
                                        onClick={() => handleDocButtonClick(index)}
                                    />
                                ))}
                            </Tabs>
                        </Grid>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2}>
                        {taskDocs.length > 0 && taskDocs[selectedTabIndex].po_state !== "approved" && (
                            <Button variant='outlined' color="success" onClick={handleApproveDocs}>
                                APPROVE DOCS
                            </Button>
                        )}
                    </Grid>
                    <Grid item xs={2} sm={2} md={2}>
                            <Button variant='outlined' color="success" onClick={handlePostDoc}>
                                POST DOC
                            </Button>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2}>
                        <Button variant='outlined' color="success" onClick={handlePostLabels}>
                            POST LABELS
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                {taskDocs.length > 0 && (
                    <div>
                        <DocInfo
                            docsFields={taskDocs[selectedTabIndex]}
                            onDocsFieldsChange={onDocsFieldsChange}
                            onChangePriceCoef={onChangePriceCoef}
                            selectedTabIndex={selectedTabIndex}
                        />
                        <DocTable
                            docsItems={taskDocs[selectedTabIndex].positions}
                            onDocItemChange={onDocItemChange}
                            orderId={taskDocs[selectedTabIndex].order_id}
                        />
                    </div>
                )}
            </Grid>
        </Grid>
    );
}

DocsEditor.propTypes = {
    taskDocs: PropTypes.array.isRequired,
    onDocsFieldsChange: PropTypes.func.isRequired,
    handleApproveDocs: PropTypes.func.isRequired,
};
