import React from 'react';
import { Grid } from '@mui/material';

export default function Company({ ship_to }){

    return (
        <Grid container>
            {ship_to.company}
        </Grid>
    );
}