import {Button, Container, Grid, TextField} from "@mui/material";
import React from "react";

const TextFieldComponent = ({id, label, value, onChange}) => (
    <Grid item xs={6} sm={6} md={6}>
        <TextField id={id} label={label} InputLabelProps={{ shrink: true }} variant="standard" value={value || ''} onChange={onChange}/>
    </Grid>
);
export default function LabelInfo({labelInfo, onSaveLabel, onChangeLabelInfo, onPrint}) {

    const handleChangeField = (event) => {
        const {id, value} = event.target;
        onChangeLabelInfo({[id]: value});
    };

    const handleSaveLabel = () => {
        onSaveLabel(labelInfo)
    }

    const fields = [
        {id: "part_number", label: "partNumber", value: labelInfo.part_number, onChange: handleChangeField},
        {id: "date_code", label: "dateCode", value: labelInfo.date_code, onChange: handleChangeField},
        {id: "manufacturer", label: "manufacturer", value: labelInfo.manufacturer, onChange: handleChangeField},
        {id: "part_description", label: "partDescription", value: labelInfo.part_description, onChange: handleChangeField},
        {id: "quantity", label: "quantity", value: labelInfo.quantity, onChange: handleChangeField},
        {id: "label_name", label: "labelName", value: labelInfo.label_name, onChange: handleChangeField},
    ];

    const taskItemIdArray = [labelInfo.task_item_id];

    return (
        <Container>
            <Grid container spacing={2}>
                {fields.map((field, index) => (
                    <TextFieldComponent key={index} id={field.id} label={field.label} value={field.value} onChange={field.onChange} />
                ))}
                <Grid item xs={6} sm={6} md={6}>
                    <Button variant={'outlined'} color="success" fullWidth onClick={() => onPrint(taskItemIdArray, 210, 297, 2, 4)}>Print Label</Button>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <Button variant={'outlined'} color="success" fullWidth onClick={handleSaveLabel}>Save Label</Button>
                </Grid>
            </Grid>
        </Container>
    );

}