import React, {useCallback, useEffect, useState} from 'react';
import { Box, Grid, Pagination } from '@mui/material';
import SearchPannell from '../SearchPannell';
import Cards from '../Cards';
import Header from '../Header';

export default function StartPage(){

    const baseRoute = process.env.REACT_APP_BASE_ROUTE
    const api_token = localStorage.getItem('token');

    const perPage = 7;
    const [searchText, setSearchText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [values,  setValues] = useState({});
    const [dealData, setDealData] = useState([]);
    const [exactSearch, setExactSearch] = useState(false);


    const handleSearch = (text) => {
        setSearchText(text);
    }

    const fetchDealData = useCallback(async () => {
        try {
            const queryParams = new URLSearchParams();
            if (searchText && searchText !== '') {
                queryParams.append('po_num_filter', searchText);
            }
            if (values.ShipToCompany && values.ShipToCompany !== '') {
                queryParams.append('ship_to_company_filter', values.ShipToCompany);
            }
            if (values.Invoice && values.Invoice !== '') {
                queryParams.append('invoice_filter', values.Invoice);
            }
            if (values.Tracking && values.Tracking !== '') {
                queryParams.append('tracking_filter', values.Tracking);
            }
            if (values.PartNumber && values.PartNumber !== '') {
                queryParams.append('part_number_filter', values.PartNumber);
            }
            if (exactSearch) {
                queryParams.append('exact_search', '1');
            } else {
                queryParams.append('exact_search', '0');
            }
            queryParams.append('page', currentPage);
            queryParams.append('per_page', perPage);

            const response = await fetch(`${baseRoute}/api/v1/orders/byfilters?${queryParams.toString()}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': api_token,
                }
            });

            const dealData = await response.json();
            setDealData(dealData);
            console.log(dealData);
        } catch (error) {
            throw error;
        }
    }, [baseRoute, api_token, searchText, values, exactSearch, currentPage, perPage]);


    useEffect(() =>{
        fetchDealData().catch((error) => {
            console.error('Error fetching deal data:', error);
        });
    }, [fetchDealData]);

    const handlePageChange = (event, page) =>{
        setCurrentPage(page);
    }

    const [loggedIn, setLoggedIn] = useState(true);
    const [isLoggedOut, setIsLoggedOut] = useState(false);
    const handleLogout = () => {
        localStorage.removeItem('token');
        setLoggedIn(false);
        setIsLoggedOut(true);
    }

    return(
        <Box>
            <Grid container spacing={5}>
                <Grid item xs={12} sm={12} md={12}>
                    <Header
                        loggedIn={loggedIn}
                        onLogout={handleLogout}
                        isLoggedOut={isLoggedOut}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Grid container spacing={3}>
                        {/* -----------------------------------------Search-pannell-------------------------------------------- */}
                        <Grid item xs={12} sm={12} md={12}>
                            <SearchPannell  onSearch={handleSearch} values={values} setValues={setValues} exactSearch={exactSearch} setExactSearch={setExactSearch} />
                        </Grid>
                        {/* -----------------------------------------Deals-cards-------------------------------------------- */}
                        <Grid item xs={12} sm={12} md={12}>
                            <Cards deals={dealData.data} />
                        </Grid>
                        {/* -----------------------------------------Pagination-------------------------------------------- */}
                        <Grid item xs={12} sm={12} md={12}>
                            <Grid container
                                  justifyContent='center'
                                  alignItems='center'
                            >
                                <Pagination
                                    count={dealData.last_page}
                                    page={currentPage}
                                    onChange={handlePageChange}
                                    color='success'
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}
