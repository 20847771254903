import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider, useNavigate } from 'react-router-dom';
import StartPage from './components/StartPage';
import CheckOrderPage from './components/CheckOrderPage';
import LogInPage from './components/LogInPage';
import RoleRequestPage from './components/RoleRequestPage';
import ManageRolesPage from './components/ManageRolesPage';
import "./Styles/style.css";
import NotFoundPage from "./components/NotFoundPage";
import {ToastContainer} from "react-toastify";
import OrderDashBoardPage from "./components/OrderDashBoardPage";
import TaskPage from "./components/TaskPage";
import ShipmentEditor from "./components/ShipmentEditor";

const haronRoute = process.env.REACT_APP_HARON_ROUTE

const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route path="/manageRoles" element={<PrivateRoute element={<ManageRolesPage />} requiredRoles={["Administrator"]} />} />
            <Route path="/roleRequest" element={<PrivateRoute element={<RoleRequestPage />} requiredRoles={["Default"]} />} />
            <Route path="/auth" element={<LogInPage />} />
            <Route path="/" element={<PrivateRoute element={<StartPage />} requiredRoles={["QCheck engineer", "Review engineer"]} />} />
            <Route path="/Editor/:po_num" element={<PrivateRoute element={<CheckOrderPage />} requiredRoles={["QCheck engineer", "Review engineer"]} />} />
            <Route path="/parcels" element={<PrivateRoute element={<OrderDashBoardPage />} requiredRoles={["Sales manager", "Review engineer"]} />} />
            <Route path="/tasks" element={<PrivateRoute element={<TaskPage />} requiredRoles={["Sales manager", "QCheck engineer", "Review engineer"]} />} />
            <Route path="/tasks/:taskNum/shipmentEditor" element={<PrivateRoute element={<ShipmentEditor />} requiredRoles={["QCheck engineer", "Review engineer"]} />} />
            <Route path="/Editor/not-found" element={<NotFoundPage/>} />
        </>
    )
);


function isTokenExpired(token) {
    try {
        const payload = JSON.parse(atob(token.split('.')[1]));
        const currentTime = Math.floor(Date.now() / 1000);
        return payload.exp < currentTime;
    } catch (error) {
        console.error("Invalid token format", error);
        return true;
    }
}

async function refreshToken() {
    try {
        const response = await fetch(`${haronRoute}/api/v1/refresh`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ refresh_token: localStorage.getItem('refresh_token') }),
        });

        if (!response.ok) {
            throw new Error(`Failed to refresh token: ${response.status}`);
        }

        const data = await response.json();
        const newToken = data.token;

        if (newToken) {
            localStorage.setItem('token', newToken);
            return newToken;
        }

        throw new Error('Token refresh response did not include a new token');
    } catch (error) {
        console.error("Error refreshing token:", error);
        throw error;
    }
}

function PrivateRoute({ element, requiredRoles }) {
    const navigate = useNavigate();

    useEffect(() => {
        const checkAuth = async () => {
            const token = localStorage.getItem('token');

            if (!token) {
                navigate('/auth');
                return;
            }

            if (isTokenExpired(token)) {
                try {
                    await refreshToken();
                } catch {
                    navigate('/auth');
                    return;
                }
            }
        };

        checkAuth();
    }, [navigate]);

    return element;
}

createRoot(document.getElementById('root')).render(
    <>
        <RouterProvider router={router} />
        <ToastContainer/>
    </>

);
