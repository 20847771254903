import React from 'react';
import {Box, Button, Card, CardContent, Stack, Typography} from '@mui/material';
import dayjs from "dayjs";


export default function SingleTask({ task, onEditButtonClick, onDeleteButtonClick, onComplectButtonClick}) {
    return (
        <Card sx={{
                    display: 'flex',
                    backgroundColor:
                    task.task_state === 'preparing'
                        ? 'rgba(255, 255, 0, 0.2)'
                        : task.task_state === 'complectating'
                        ? 'rgba(171, 205, 239, 0.2)'
                        : task.task_state === 'in_transit'
                        ? 'rgba(127, 255, 212, 0.2)'
                        : 'inherit',
                }}
        >
            <Box sx={{ flexGrow: 1, m: 2 }}>
                <CardContent>
                    <Typography variant="body1">{`Task #${task.task_num}`}</Typography>
                    {task.task_type === "external" ? (
                        <Typography variant="body1">{`Clients QTY: ${task.clients_count}`}</Typography>

                    ) : (
                        <Typography variant="body1">{`Route Name: ${task.route_name}`}</Typography>
                    )}
                    <Typography variant="body1">{`Shipment Date: ${dayjs(task.task_date).format('DD.MM.YYYY')}`}</Typography>
                    <Typography variant="body1">{`Task State: ${task.task_state}`}</Typography>
                </CardContent>
            </Box>

            <Box sx={{ flexShrink: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', m: 2 }}>
                <Stack spacing={2}>
                    {
                        <Button variant="contained" color="success" fullWidth onClick={onEditButtonClick}>
                            Edit
                        </Button>
                    }
                    {(task.task_state === "complectating" || task.task_state === "in_transit") ? (
                        <Button variant="outlined" color="success" fullWidth onClick={onComplectButtonClick}>
                            Complect
                        </Button>
                    ):(
                        <>
                            {task.task_state === "preparing" && (
                                <Button variant="outlined" color="success" fullWidth onClick={onDeleteButtonClick}>
                                    Delete
                                </Button>
                            )}
                        </>
                    )}
                </Stack>
            </Box>
        </Card>

    );
}
