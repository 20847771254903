import React, { useState } from 'react';
import { Grid, Icon } from '@mui/material';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import ErrorDialog from '../../ErrorDialog';

export default function Location({ ship_to }) {
    const [error, setError] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    const openErrorDialog = (errorMsg) => {
        setError(errorMsg);
        setDialogOpen(true);
    };

    const renderLocation = () => {
        if (!ship_to || typeof ship_to !== 'object') {
            return (
                <span onClick={() => openErrorDialog("Invalid ship_to data.")} className="error-text">
                    Invalid Ship To Data
                </span>
            );
        }

        return (
            <Grid item>
                <Icon>
                    <LocationOnOutlinedIcon />
                </Icon>
                {ship_to.address ? (
                    ship_to.address
                ) : (
                    <span onClick={() => openErrorDialog("Address is missing.")} className="error-text">
                        Address Missing
                    </span>
                )}
            </Grid>
        );
    };

    return (
        <Grid container>
            {renderLocation()}
            <ErrorDialog open={dialogOpen} onClose={() => setDialogOpen(false)} error={error} />
        </Grid>
    );
}

