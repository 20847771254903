import {
    Button,
    Card,
    CardActions,
    CardContent, CardMedia, Dialog, DialogContent,
    Grid,
    Typography,
} from "@mui/material";
import InvoiceNum from "../../Cards/SingleCard/InvoiceNum";
import PhotoGallery from "../../PhotoGallery";
import React, {useState} from "react";
import default_ico from "../../../images/default_ico.png";



export default function SingleBox({box, boxPhotos, boxNum, onEditButtonClick, onDeleteButtonClick, handleDeletePhoto, handleUpdatePhoto, setIsProduct}){

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setIsProduct(false);
        setOpen(true);
    };

    const handleClose = () => {
        setIsProduct(true);
        setOpen(false);
    };

    return(
        <Card sx={{ display: 'flex' }}>
            <Grid container
                  alignItems="center"
                  justifyContent="center">

                <Grid item xs={12} sm={2} md={2}>
                    <Button onClick={handleClickOpen}>
                        {boxPhotos && boxPhotos.length > 0 ? (
                            <CardMedia
                                component="img"
                                sx={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                    borderRadius: "10%"

                                }}
                                image={boxPhotos[0].dataUrl}
                                alt={boxPhotos[0].title}
                            />
                        ) : (
                            <CardMedia
                                component="img"
                                sx={{ width: "100%" }}
                                image={default_ico}
                            />
                        )}
                    </Button>

                    <Dialog open={open} onClose={handleClose}>
                        <DialogContent>
                            <PhotoGallery photosGallery={boxPhotos} handleUpdatePhoto={handleUpdatePhoto} handleDelete={handleDeletePhoto}/>
                        </DialogContent>
                    </Dialog>
                </Grid>

                <Grid item xs={12} sm={10} md={10}>
                    <Grid container
                          alignItems="center"
                          justifyContent="center"
                          spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography variant={"h6"}>
                                Box {boxNum}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8}>
                            <Grid container>
                                <Grid item xs={12} sm={4} md={4}>
                                    <CardContent>
                                        {box.invoices && (
                                            <>
                                                {box.invoices.length > 0 ? (
                                                    <Typography variant={"body2"}>
                                                        <b>invoices:</b> <InvoiceNum invoices={box.invoices}/>
                                                    </Typography>
                                                ) : (
                                                    <Typography variant={"body2"}>
                                                        <b>Without invoices</b>
                                                    </Typography>
                                                )}
                                            </>
                                        )}


                                           {box.track_num ? (
                                               <Typography variant={"body2"}>
                                                   <b>tracking:</b> {box.track_num}
                                               </Typography>
                                           ) : (
                                               <Typography variant={"body2"}>
                                                   <b>Without tracking</b>
                                               </Typography>
                                               )}
                                        <Typography variant={"body2"}>
                                            <b>weight:</b> {box.weight}
                                        </Typography>
                                    </CardContent>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4}>
                                    <CardContent>
                                        <Typography variant={"body2"}>
                                            <b>width:</b> {box.width}
                                        </Typography>
                                        <Typography variant={"body2"}>
                                            <b>height:</b> {box.height}
                                        </Typography>
                                        <Typography variant={"body2"}>
                                            <b>length:</b> {box.length}
                                        </Typography>
                                        <Typography variant={"body2"}>
                                            <b>volume weight:</b> {parseInt(box.length)*parseInt(box.width)*parseInt(box.height)/5000}
                                        </Typography>
                                    </CardContent>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4}>
                                    <CardContent>
                                        <Typography variant={"body2"}>
                                            <b>comment:</b> {box.comment}
                                        </Typography>
                                    </CardContent>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                            <Grid container spacing={2}
                                alignItems="center"
                                justifyContent="center">

                                <Grid item xs={12} sm={12} md={12}>
                                    <CardActions>
                                        <Grid container spacing={2}
                                              direction='row'
                                              justifyContent='center'
                                              alignItems='center'>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Button variant='contained' color="success" fullWidth onClick={() => onEditButtonClick(box)}>Edit</Button>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12}>
                                                {box.task_box_state !== 'shipped' &&
                                                    <Button variant='outlined' color="success" fullWidth onClick={() => onDeleteButtonClick(box)}>Delete</Button>
                                                }
                                            </Grid>
                                        </Grid>
                                    </CardActions>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
}