import React, {useCallback, useEffect, useState} from 'react';
import { Box, Container, Grid, TextField, Pagination } from '@mui/material';
import Header from '../Header';
import RequestsCards from '../RequestsCards';




export default function ManageRolesPage() {
    const baseRoute = process.env.REACT_APP_BASE_ROUTE
    const api_token = localStorage.getItem('token');

    const perPage = 7;
    const [currentPage, setCurrentPage] = useState(1);
    const [requestsData, setRequestsData] = useState([]);
    const [requestNumber, setRequestNumber] = useState('');
    const [userName, setUserName] = useState('');
    const [sDeleted, setSDeleted] = useState(false)
    const handleRequestNumberFilter = (event) => {
        setRequestNumber(event.target.value);
    }
    const handleUserNameFilter = (event) => {
        setUserName(event.target.value);
    }

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    }
    const fetchRequests = useCallback(async () => {
        try {
            const queryParams = new URLSearchParams();

            if (requestNumber) {
                queryParams.append('request_number_filter', requestNumber);
            }
            if (userName) {
                queryParams.append('user_name_filter', userName);
            }
            if (perPage) {
                queryParams.append('per_page', perPage);
            }
            if (currentPage) {
                queryParams.append('page', currentPage);
            }

            const response = await fetch(`${baseRoute}/api/v1/roles/requests?${queryParams.toString()}`,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': api_token,
                },
            });

            const requestsData = await response.json();
            setRequestsData(requestsData.data);
            console.log(requestsData);

        } catch (error) {
            console.log('Ошибка при загрузке данных', error);
        }
    }, [baseRoute, api_token, requestNumber, userName, currentPage, perPage]);

    useEffect(() => {
        fetchRequests().catch((error) => {
            console.error('Error fetching requests:', error);
        });
    }, [fetchRequests])


    const [loggedIn, setLoggedIn] = useState(true);
    const [isLoggedOut, setIsLoggedOut] = useState(false);
    const handleLogout = () => {
        localStorage.removeItem('token');
        setLoggedIn(false);
        setIsLoggedOut(true);
    }
    return(
        <Box>
            <Grid container spacing={5}>
                <Grid item xs={12} sm={12} md={12}>
                    <Header
                        loggedIn={loggedIn}
                        onLogout={handleLogout}
                        isLoggedOut={isLoggedOut}
                    />
                </Grid>
                
                <Grid item xs={12} sm={12} md={12}>
                    <Container>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <TextField 
                                            variant='outlined'
                                            type='search'
                                            label='Введите номер заявки'
                                            fullWidth
                                            value={requestNumber}
                                            onChange={handleRequestNumberFilter}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <TextField 
                                            variant='outlined'
                                            type='search'
                                            label='Введите UserName'
                                            fullWidth
                                            value={userName}
                                            onChange={handleUserNameFilter}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <RequestsCards requests={requestsData} sDeleted={sDeleted} setSDeleted={setSDeleted}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Grid container
                                    justifyContent='center'
                                    alignItems='center'
                                >
                                    <Pagination
                                        count={requestsData.last_page}
                                        page={currentPage}
                                        onChange={handlePageChange}
                                        color='success'
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
            </Grid>
        </Box>
    );
}