import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Grid,
    useTheme,
    useMediaQuery,
    Typography
} from '@mui/material';
import default_ico from '../../../images/default_ico.png';
import TrackingNum from './TrackingNum';
import InvoiceNum from './InvoiceNum';
import Company from './Company';
import Location from './Location';
import "./Styles/style.css";
import ErrorDialog from '../../ErrorDialog';
const Section = ({ children }) => (
    <div className="section">
        <Typography component="div">{children}</Typography>
    </div>
);

export default function SingleCard({ deal }){

    const theme = useTheme();
    const xsScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [error, setError] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    useEffect(() => {
        if (!deal || !deal.po_num) {
            setError(new Error("Invalid deal data"));
            setDialogOpen(true);
        }
    }, [deal]);

    const navigate = useNavigate();
    const handleOrder = () => {
        try {
            navigate(`/Editor/${deal.po_num}`);
            // window.location.href = `/Editor/${deal.po_num}`;
            localStorage.setItem('order_id', deal.po_num);
        } catch (error) {
            setError(error);
            setDialogOpen(true)
        }
    }

    const closeDialog = () => {
        setError(null);
        setDialogOpen(false);
    };

    return(
        <Card sx={{ display: 'flex' }}>
            <Grid container spacing={0}
                  justifyContent="space-between"
                  alignItems="center"
            >
                <Grid item xs={12} sm={3} md={2}>
                    <Grid container
                          alignItems="center"
                          justifyContent="center"
                    >
                        <CardMedia
                            component="img"
                            sx={{ width: 151 }}
                            image={default_ico}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={7} md={8}>
                    <CardContent sx={{ flex: '1 1 auto', maxHeight: 'calc(100% - 48px)', wordWrap: 'break-word' }}>
                        <Section>
                            <b>Deal#:</b> {deal?.po_num ?? 'N/A'}{' '}
                            <b>po date:</b> {deal?.po_date ? new Date(deal.po_date).toLocaleDateString('en-GB') : 'N/A'}{' '}
                            <b>arrival date:</b> {deal?.arr_date ? new Date(deal.arr_date).toLocaleDateString('en-GB') : 'N/A'}{' '}
                        </Section>
                        <Section>
                            <Company ship_to={deal?.ship_to} />
                        </Section>
                        <Section>
                            <Grid container spacing={3} alignItems="flex-end">
                                <Grid item><b>positions:</b> {deal?.positions ?? 'N/A'}{' '}</Grid>
                                <Grid item><Location ship_to={deal?.ship_to ?? []} /></Grid>
                            </Grid>
                        </Section>
                        <Section>
                            <Grid container>
                                <Grid item><b>Invoices:</b></Grid>
                                <Grid item>
                                    <div className="invoices-list">
                                        <InvoiceNum invoices={deal?.invoices ?? []}/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Section>
                        <Section>
                            <Grid container>
                                <Grid item><b>Tracking:</b></Grid>
                                <Grid item>
                                    <div className="tracking-list">
                                        <TrackingNum trackings={deal?.tracking ?? []} />
                                    </div>
                                </Grid>
                            </Grid>
                        </Section>
                    </CardContent>
                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                    <CardActions sx={{ flexGrow: 0 }}>
                        <Grid container spacing={2}
                              direction='row'
                              justifyContent={xsScreen ? 'center' : 'flex-start'}
                              alignItems={xsScreen ? 'center' : 'flex-end'}
                        >
                            <Grid item xs={6} sm={12} md={12}>
                                <Button variant='contained' color="success" fullWidth onClick={handleOrder}>Q/Check</Button>
                            </Grid>
                            <Grid item xs={6} sm={12} md={12}>
                                <Button variant='outlined' color="success" fullWidth>Review</Button>
                            </Grid>
                        </Grid>
                    </CardActions>
                </Grid>
            </Grid>
            <ErrorDialog open={dialogOpen} onClose={closeDialog} error={error} />
        </Card>
    );
}
